<template>
  <div>
    <h1 class="mt-4 mb-4">Pagos</h1>

    <p>Aqui puedes ver tus pagos.</p>

    <button type="button" @click="showPaymentInfo = !showPaymentInfo" class="btn m-1 btn-outline-warning"><i class="fas fa-info"></i> Info de pago</button>

    <div v-show="showPaymentInfo">
      <InfoPago></InfoPago>
    </div>

    <p v-show="errorPagos">Error obteniendo los pagos</p>

    <h3 v-show="loadingPagos" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

    <div v-if="!errorPagos && !loadingPagos" class="text-center">
      <p class="mt-3"><strong>{{ pagos.length }}</strong> pago/s</p>

      <div v-for="pago in pagos" :key="pago._id" class="row m-3 shadow text-center align-items-center border bg-light">
        <div class="col-sm">
          <div class="card-body">
            <h5><i class="fas fa-calendar-day text-primary"></i> {{ new Date(pago.fecha).toLocaleDateString() }}</h5>
          </div>
        </div>

        <div class="col-sm">
          <div class="card-body">
            <h5 class="text-capitalize"><i class="fas fa-money-check-alt text-success"></i> {{ pago.tipo }}</h5>
          </div>
        </div>

        <div class="col-sm">
          <div class="card-body">
            <h5><i class="fas fa-coins text-warning"></i> {{ pago.cantidad }} €</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../config'
  import InfoPago from '../components/InfoPago.vue'

  export default {
    data() {
      return {
        pagos: [],
        loadingPagos: true,
        errorPagos: false,
        showPaymentInfo: false
      }
    },
    components: {
      InfoPago
    },
    methods: {
      async getPagos() {
        try {
          const res = await axios.get(config.apiUrl + '/usuarios/pagos')

          this.pagos = res.data

          this.loadingPagos = false
        } catch (e) {
          this.loadingPagos = false

          this.errorPagos = e.response.data
        }
      }
    },
    mounted() {
      this.$store.commit('setPanelLayout')
      this.getPagos()
    }
  }
</script>
