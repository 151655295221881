<template>
  <div class="row text-center">
    <div class="col-md card m-3 shadow bg-light">
      <div class="card-body">
        <img src="../assets/images/bizum.jpg" class="card-img-top img-fluid" style="max-width: 150px">
        <hr>
        <div class="text-left">
          <p><strong>Número:</strong> 613 08 28 40</p>
          <p><strong>Importe:</strong> 10 €</p>
          <p><strong>Concepto:</strong> {{ perfil.usuario }}</p>
        </div>
      </div>
    </div>

    <div class="col-md card m-3 shadow bg-light">
      <div class="card-body">
        <img src="../assets/images/transfer.png" class="card-img-top img-fluid" style="max-width: 70px">
        <hr>
        <div class="text-left">
          <p><strong>Número de cuenta:</strong> ES09 1563 2626 3632 6254 9577</p>
          <p><strong>Beneficiario:</strong> Álvaro Fernández-Llebrez Panizzoli</p>
          <p><strong>Importe:</strong> 10 €</p>
          <p><strong>Concepto:</strong> {{ perfil.usuario }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      perfil() {
        return this.$store.state.perfil
      }
    }
  }
</script>
